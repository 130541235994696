.header{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    z-index: 20;
    transition: all .3s ease-in-out;
}
.header__container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.header__nav{
    margin-left: 1.3rem;
}
.header__menu{
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    & li{
        display: inline-block;
        margin-left: 1.2rem;
        margin-right: 1.2rem;
        & a{
            text-transform: uppercase;
            font-size: 1.2rem;
            line-height: 2.8rem;
            &:hover{
                color: red;
            }
        }
    }
}
.header__soc{
    display: inline-block;
    margin-right: 0.9rem;
    & a{
        margin-right: 1rem;
        & svg path{
            transition: all 0.5s ease;
        }
        &:hover svg path.red{
            fill:red;
        }
        &:hover svg path.white{
            fill:white;
        }
    }
}
.header__actions {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.header__order{
    width: 16.5rem;
    height: 3.3rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #FF0000;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    text-transform: uppercase;
    transition: all 0.2s ease;
    &:hover{
        background: #ff0;
        color: #203a3d;
    }
}

.header-phone-number {
  position: absolute;
  top: 70px;
  right: 50px;
  font-weight: 600;
}

.header_shadow {
  .header-phone-number {
    padding: 20px 50px 20px 50px;
    background-color: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    top: 55px;
    right: 0;
  }
}