$lg_s: 10;
$md_s: 8;
$xs_s: 7;



@import "sub-styles/fonts.scss";
@import "sub-styles/layout.scss";
@import "sub-styles/mfp.scss";
@import "sub-styles/animations.scss";


@import "sub-styles/header.scss";

.is-invalid {
    border: 1px solid red;
}

.main{
  background-image: url(../img/bg0@2x.png);
  background-position: center bottom;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  &:before{
    content:' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20rem;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */

  }
}
.main__header{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding-bottom: 10rem;
}
.main__form-bg{
  margin-bottom: 12rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url(../img/bg_form.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 8.3rem;
  z-index: 2;
}
.main__form__input{
  background: #fff;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 0px;
  width: 20rem;
  height: 4rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  font-size: 1.4rem;
  color: #707070;
  &::placeholder{
    color: #707070;
  }
}
.main__form__submit{
  margin-left: 1rem;
  margin-right: 1rem;
  width: 28.5rem;
  height: 4rem;
  background: #FFFF00;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #203A3D;
  border: 0px;
  text-transform: uppercase;
  transition: all 0.2s ease;
  &:hover{
    background: red;
    color: #fff;
  }
}

.welcome{
  height: 80rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 10rem;
  position: relative;
}
.welcome__bg{
  position: absolute;
  right: 0;
  top: 0;
  width: 53.05%;
  height: 100%;
  background-image: url(../img/bg_welcome@x2.png);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 100% auto;
}
.welcome__info{
  width: 46rem;
  max-width: 100%;
  text-align: center;
  position: relative;
  z-index: 10;
}
.section__sub-header{
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.5rem;
}
.section__sub-header-bold{
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.5rem;
}
.section__text{
  font-weight: 300;
  line-height: 2.5rem;
  margin-top: 1.4rem;
  font-size: 1.6rem;
}
.text-normal{
  font-weight: 400;
  width: 38.5rem;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 2.4rem;
}

.resort{
  background: rgba(242, 239, 238, 0.5);
  height: 64rem;
  padding-top: 5rem;
  position: relative;
  &:after{
    content:' ';
    display: block;
    background: url(../img/waves.png) repeat-x 0px 100% / 100% auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10rem;
  }
  & .section__text{
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
}
.resort__bg{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 55.34%;
  max-width: 100rem;
  height: 100%;
  background-image: url(../img/bg_resort@2x.png);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: auto 100%;
}
.section__header{
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}
.resort__container{
  margin-top: 6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
}
.resort__info{
  width: 46rem;
  max-width: 100%;
  position: relative;
  z-index: 10;
}
.resort__sub-header{
  font-size: 2.4rem;
  line-height: 2rem;
  font-weight: 700;
}
.section__order{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFF00;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 0px;
  width: 16.5rem;
  height: 3.8rem;
  font-size: 1.2rem;
  color: #725303;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 1.5rem;
  transition: all 0.2s ease;
  &:hover{
    background: red;
    color: #fff;
  }
}

.dahang{
  height: 57.5rem;
  padding-top: 7.5rem;
  position: relative;
  & .section__order{
    margin-top: 2.5rem;
  }
}
.dahang__bg{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60.69%;
  max-width: 100rem;
  height: 100%;
  background-image: url(../img/bg_danang@2x.png);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: auto 100%;
}
.dahang__info{
  width: 54.5rem;
  max-width: 100%;
  position: relative;
  z-index: 10;
}

.fukuok{
  height: 62.6rem;
  padding-top: 11.8rem;
  background: url(../img/bg_fukuok@2x.png);
  background-size: 52.7% auto;
  background-position: 0px 100%;
  background-repeat: no-repeat;
}
.fukuok__container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.fukuok__info{
  width: 50.8rem;
  max-width: 100%;
  position: relative;
  z-index: 10;
}
.fukuok__text {
    margin-top: 0rem;
}

.fanet{
  height: 70rem;
  padding-top: 11.9rem;
  background: url(../img/bg_fanet.png);
  background-size: 48.62% auto;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  & .section__order{
    margin-top: 3.4rem;
  }
}
.fanet__container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.fanet__info{
  width: 51.6rem;
  max-width: 100%;
  position: relative;
  z-index: 10;
}

.hotels{
  padding-top: 7.7rem;
  padding-bottom: 10.8rem;
}
.hotels__grid{
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.hotels__item{
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 3rem;
  width: 33%;
  max-width: 36rem;
  height: 32.5rem;
  position: relative;
  cursor: pointer;
  &:after{
    content:' ';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 16.5rem;
    background: linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, rgba(0, 0, 0, 0.7) 83.86%);
    z-index: 2;
  }
}
.hotels__figure{
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.hotels__img{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.hotels__item__info{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  color: #fff;
}

.hotels__stars{
  padding-left: 1.5rem;
  & svg{
    width: 1.35rem;
    height: 1.7rem;
    vertical-align: bottom;
  }
}
.hotels__stars__text{
  display: inline-block;
  font-size: 1.4rem;
  text-transform: capitalize;
  line-height: 1;
  vertical-align: bottom;
}
.hotels__item__name{
  padding-left: 1.5rem;
  margin-top: 0.6rem;
  line-height: 2.4rem;
  margin-bottom: 0.8rem;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
}
.hotels__stars{
  display: inline-block;
}
.hotels__item__footer{
  padding-left: 1.5rem;
  margin-bottom: 0.6rem;
  background: #1C4F6B;
  padding-right: 1.5rem;
  height: 3.8rem;
  width: 27.4rem;
  display: inline-flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 0.7rem;
  padding-top: 0.7rem;
  @media screen and (max-width: 992px) {
    width: 22.4rem;
  }
}
.hotels__item__bb{
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1;
}
.hotels__item__price{
  color: #89DEF9;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
}

.know{
  padding-top: 7.6rem;
  height: 67.4rem;
  background: url(../img/bg_know@2x.png);
  background-size: 50.25% auto;
  background-position: 100% 100%;
  background-repeat: no-repeat;
}
.know__info{
  margin-top: 7.7rem;
}
.know__info__item{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1.7rem;
  & p{
    margin-bottom: 0;
  }
}
.know__info__item-center{
  align-items: center;
}
.know__info__item__figure{
  width: 6.4rem;
  text-align: center;
}
.know__info__item__text{
  margin-left: 3rem;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2rem;
  max-width: 55.6rem;
  flex: 1;
}
.know__info__item__text2{
  max-width: 28.7rem;
}
.know__info__item__text3{
  max-width: 36rem;
}
.know__info__item__text4{
  max-width: 33.5rem;
}
.know__info__item__text5{
  max-width: 36.0rem;
}

.why{
  height: 75.4rem;
  padding-top: 5.75rem;
  background-image: url(../img/bg_why.png);
  background-position: calc(50% - 13rem) calc(100% - 9rem);
  background-repeat: no-repeat;
}
.why__header{
  font-weight: 500;
  font-size: 3.5rem;
  line-height: 4.5rem;
}
.why__container{
  margin-top: 6.7rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.why__item{
  margin-left: 3.7rem;
  margin-right: 3.7rem;
  text-align: center;
}
.why__img{
  height: 9rem;
}
.why__item__text{
  margin-top: 2rem;
  font-size: 1.7rem;
  line-height: 2.3rem;
  color: #656565;
  text-align: center;
}
.why__blue__order{
  margin: 0 auto;
  margin-top: 16.4rem;
}

.footer{
  background: url(../img/bg_footer.png) 50% 50% / cover;
}
.footer__container{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 15rem;
  color: #fff;
}
.footer__text{
  margin-left: 5rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  & p{
    margin-bottom: 0;
  }
}
.footer__logo__img {
    width: 14.6rem;
}
.footer__soc{
  & a{
    margin-right: 1.1rem;
    & svg path.white{
      fill: #fff;
    }
    & svg path.black{
      fill: #000;
    }
    &:hover svg path{
      fill: inherit;
    }
  }
}
.footer-phone-number {
  padding: 30px;
  font-weight: 600;
}
.footer__order{
  width: 16.5rem;
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.5rem;
  background: #FF0000;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-transform: uppercase;
  margin-left: 2.3rem;
  &:hover{
    background: #fff;
    color: red;
  }
}

.footer__actions{
  flex:1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & a svg path{
    transition:all 0.2s ease;
  }
  & a:hover svg path.white{
    fill: red;
  }
  & a:hover svg path.black{
    fill: #000;
  }
}

.header_shadow {
    position: fixed;
    background: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 1px 1px 4px rgba(0,0,0,.2);
    & .header__logo__img {
        height: 4rem;
    }
}

@import "sub-styles/retina.scss";


@media (max-width:1700px){
  .welcome{
    height: 70rem;
  }
  .resort{
    height: 54rem;
  }
  .dahang__bg{
    background-position: 0px 100%;
  }
}
@media (max-width:1450px){
  .welcome{
    height: 60rem;
  }
  section{
    min-height: 0;
  }
  section.welcome{
    min-height: 0;
  }
  .main{
    background-image: url(../img/bg0.png);
  }
  .welcome__bg{
    background-image: url(../img/bg_welcome.png);
    background-position: 0px 100%;
    background-size: auto 100%;
  }
  .resort__bg{
    background-image: url(../img/bg_resort.png);
  }
  .dahang__bg{
    background-image: url(../img/bg_danang.png);
  }
  .fukuok{
    background-image: url(../img/bg_fukuok.png);
  }
  .fanet{
    background-image: url(../img/bg_fanet.png);
  }
  .know__bg{
    background-image: url(../img/bg_know.png);
  }
}

@media (max-width:1150px){
  .hotels__item{
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width:2050px){
  html{
    font-size: 16px;
  }
}

@import "sub-styles/mobile.scss";