*{
    outline:none!important;
}

*:-webkit-scrollbar-button{
    background-image: url("");
    background-repeat: no-repeat;
    width: 3px;
    height: 0px;
}
*::-webkit-scrollbar-track{
    background: transparent;
}
*::-webkit-scrollbar-thumb{
    -webkit-border-radius: 0px;
    border-radius: 90px;
    background: red;
}
*::-webkit-scrollbar-thumb:hover{
    background: red;
}
*::-webkit-resizer{
    background-image: url("");
    background-repeat: no-repeat;
    width: 3px;
    height: 0px;
}
*::-webkit-scrollbar,{
    width: 3px;
}

a{
  transition: all 0.2s ease;
  color: inherit;
  &:hover{
    text-decoration: none;
    color: inherit;
  }
}

html{
    font-size: 10px;
    position: relative;
}
body{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    position: relative;
    font-size: 1.5rem;
    color: #0d0d1e;
    letter-spacing: 0em;
}

.container{
    width: 100%;
    max-width: 117rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin: 0 auto;
}