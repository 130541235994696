.white-popup {
    position: relative;
    padding: 0px;
    width: auto;
    margin: 20px auto;
    border-radius: 10px;
    overflow: hidden;
}


.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}


/*

====== Zoom effect ======

*/
.mfp-zoom-in {
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }

  &.mfp-bg {
    opacity: 0;
      transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {
    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
  }
}

/*

====== Move-horizontal effect ======

*/
.mfp-move-horizontal {

    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transition: all 0.3s;
        transform: translateX(-50px);
    }

    &.mfp-bg {
        opacity: 0;
        transition: all 0.3s;
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: translateX(0);
        }
        &.mfp-bg {
            opacity: 0.8;
        }
    }

    /* animate out */
    &.mfp-removing {
        .mfp-with-anim {
            transform: translateX(50px);
            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }
    }
}


/*

====== Zoom-out effect ======

*/
.mfp-zoom-out {
    /* start state */
    .mfp-with-anim {
        opacity: 0;
        transition: all 0.3s ease-in-out;
        transform: scale(1.3);
    }
    &.mfp-bg {
        opacity: 0;
        transition: all 0.3s ease-out;
    }
    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            opacity: 1;
            transform: scale(1);
        }
        &.mfp-bg {
            opacity: 0.8;
        }
    }
    /* animate out */
    &.mfp-removing {
        .mfp-with-anim {
            transform: scale(1.3);
            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }
    }
}


/*

====== Move-from-top effect ======

*/
.mfp-move-from-top {
  .mfp-content {
   vertical-align:top;
  }
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s;
    transform: translateY(-100px);
  }
  &.mfp-bg {
    opacity: 0;
      transition: all 0.2s;
  }
  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: translateY(0);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }
  /* animate out */
  &.mfp-removing {
    .mfp-with-anim {
      transform: translateY(-50px);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
  }
}

.order_modal{
  width: 94.6rem;
  max-width: 100%;
  height: 55rem;
  background: url(../img/bg_modal.png) 0px 50% / contain no-repeat;
  border-radius: 0px;
}
.order_modal__container{
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  padding: 3rem;
  height: 100%;
}
.order_modal__form{
  width: 38rem;
  height: 100%;
  background: rgba(28, 79, 107, 0.8);
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  padding-top: 5rem;
  position: relative;
}
.product_modal__close{
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  width: 1.7rem;
  height: 1.7rem;
  background-color: transparent;
  border: 0px;
  padding-left: 0;
  padding-right: 0;
  & svg{
    width: 100%;
    height: 100%;
  }
}
.action__form__header{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 4rem;
  color: #fff;
  text-align: center;
}
.action__form__inputs{
  margin-top: 2.5rem;
}
.action__form__input{
  margin-top: 1.5rem;
  background: #fff;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: #656565;
  width: 100%;
  height: 4.5rem;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  &:placeholder{
    color: #656565;
  }
}
.action__form__btn{
  margin-top: 1.5rem;
  color: #203A3D;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.5rem;
  background: #FFFF00;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 100%;
  height: 4.5rem;
  border: 0px;
  text-transform: uppercase;
}

.action__form__text {
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #fff;
    font-weight: 400;
    text-align: center;
}
.action__form__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.action__form {
    height: 100%;
}
#ty_modal .order_modal__form{
  padding-top: 0;
}