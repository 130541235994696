@-webkit-keyframes slideBottom {
  0% {
    -webkit-transform: translateY(-12%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
    opacity: 1;
  }
}

.slideUp{
  animation-name: slideUp;
  -webkit-animation-name: slideUp;

  animation-duration: 0.9s;
  -webkit-animation-duration: 0.9s;

  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;

  opacity:1;
}