@font-face {
  font-weight: 300;
  font-style: normal;
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Light.eot");
  src: url("../fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Light.woff") format("woff"),
    url("../fonts/Roboto-Light.woff2") format("woff2"),
    url("../fonts/Roboto-Light.svg") format("svg"),
    url("../fonts/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Regular.eot");
  src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Regular.woff") format("woff"),
    url("../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto-Regular.svg") format("svg"),
    url("../fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Medium.eot");
  src: url("../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Medium.woff") format("woff"),
    url("../fonts/Roboto-Medium.woff2") format("woff2"),
    url("../fonts/Roboto-Medium.svg") format("svg"),
    url("../fonts/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Bold.eot");
  src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Roboto-Bold.woff") format("woff"),
    url("../fonts/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/Roboto-Bold.svg") format("svg"),
    url("../fonts/Roboto-Bold.ttf") format("truetype");
}